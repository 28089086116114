import { map } from "lodash";
import { useCallback } from "react";

import { Page, ID, HasRefs, RichText } from "@api";

import { useEditInAppCommands } from "@state/app";
import {
  useLazyEntities,
  useLazyEntity,
  useQueueUpdates,
  useUpdateEntity,
} from "@state/generic";
import { useLazyPropertyDef } from "@state/databases";
// import { useAutoLinkRelated } from "@state/pages/effects";

import { useShowMore } from "@utils/hooks";
import { Maybe, safeAs, when } from "@utils/maybe";
import {
  asAppendMutation,
  asMutation,
  asUpdate,
} from "@utils/property-mutations";
import { ensureArray, ensureMany } from "@utils/array";
import { useGoTo, usePushTo } from "@utils/navigation";

import { usePageId } from "@ui/app-page";
import { Centered, Container } from "@ui/container";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import {
  ArrowUpRight,
  Emoji,
  Icon,
  iconFromString,
  RelationIcon,
} from "@ui/icon";
import { Sheet } from "@ui/sheet-layout";
import { WorkflowActions } from "@ui/workflow-action-button";
import { EditableHeading } from "@ui/editable-heading";
import { TemplateBanner } from "@ui/template-banner";
import { LabelledValue } from "@ui/property-value";
import { PeopleStack } from "@ui/people-stack";
import { AddMenuItem, ShowMoreMenuItem } from "@ui/menu-item";
import { GlobalEntitySelect, GlobalMultiEntitySelect } from "@ui/select";
import { PaneContainer, PaneHeader } from "@ui/pane-header";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { ResourceExtractor } from "@ui/resource-extractor";
import { EntityHeaderBar } from "@ui/entity-header-bar";
import { RealTimeDocumentEditor } from "@ui/real-time-field";
import { OnHover } from "@ui/on-hover";
import { Button } from "@ui/button";
import { EmojiSelect } from "@ui/select/emoji";
import { PropertyValueButton } from "@ui/property-value-button";

import { PaneOpts } from "../types";
import { render, toEngine } from "..";

import styles from "./styles.module.css";

export const PagePane = ({ id, item: page }: PaneOpts<Page>) => {
  const mutate = useUpdateEntity(page.id, page.source.scope);
  const editInAppCommands = useEditInAppCommands();
  const ownerProp = useLazyPropertyDef(page.source, {
    field: "owner",
    type: "relation",
  });

  const handleBodyChanged = useCallback(
    (n: Maybe<RichText>) =>
      mutate(asMutation({ field: "body", type: "rich_text" }, n)),
    [mutate]
  );

  return (
    <Sheet size="primary">
      {!!page.template && <TemplateBanner />}

      <VStack gap={0}>
        <Container>
          <EntityHeaderBar entity={page} />
        </Container>

        <OnHover.Trigger>
          <Centered padding="horizontal">
            <VStack gap={20}>
              <SpaceBetween direction="horizontal" align="stretch" gap={12}>
                <Container
                  gap={6}
                  padding="none"
                  inset="bottom"
                  stack="vertical"
                >
                  <Container
                    gap={0}
                    stack="horizontal"
                    inset="left"
                    padding="none"
                  >
                    {!page.icon && (
                      <OnHover.Target>
                        <PropertyValueButton
                          entity={page}
                          field="icon"
                          type="text"
                        />
                      </OnHover.Target>
                    )}
                    <OnHover.Target show={!!page.status}>
                      <PropertyValueButton
                        entity={page}
                        field="status"
                        type="status"
                      />
                    </OnHover.Target>
                    {!page.owner && (
                      <OnHover.Target>
                        <PropertyValueButton
                          entity={page}
                          field="owner"
                          type="relation"
                        />
                      </OnHover.Target>
                    )}
                  </Container>

                  <HStack align="baseline" gap={0}>
                    {!!page.icon && (
                      <EmojiSelect
                        emoji={page.icon || ""}
                        onChange={(i) =>
                          mutate(asMutation({ field: "icon", type: "text" }, i))
                        }
                      >
                        <Button inset size="small" subtle>
                          <Icon
                            size="large"
                            icon={when(page.icon, iconFromString) || Emoji}
                          />
                        </Button>
                      </EmojiSelect>
                    )}
                    <EditableHeading
                      key={page.id}
                      text={page.title || ""}
                      placeholder="Untitled"
                      onChange={(text) => {
                        when(text, (i) =>
                          mutate(
                            asMutation({ field: "title", type: "text" }, i)
                          )
                        );
                      }}
                    />
                  </HStack>
                </Container>

                {page.owner && (
                  <HStack>
                    <LabelledValue label="Owner">
                      <Container
                        size="half"
                        padding="vertical"
                        onClick={() => editInAppCommands(ownerProp, page)}
                      >
                        <PeopleStack
                          people={when(page.owner, ensureArray) || []}
                          size="xlarge"
                        />
                      </Container>
                    </LabelledValue>
                  </HStack>
                )}
              </SpaceBetween>

              {!page.template && (
                <HStack fit="container" gap={4} className={styles.hideEmpty}>
                  <WorkflowActions entity={page} />
                </HStack>
              )}
            </VStack>
          </Centered>
        </OnHover.Trigger>
      </VStack>

      <Centered
        key={page.id}
        stack="vertical"
        gap={30}
        className={styles.noPadTop}
      >
        <RealTimeDocumentEditor
          entity={page.id}
          field="body"
          content={page.body}
          onChanged={handleBodyChanged}
          className={styles.editor}
          newLineSpace="large"
          placeholder="Start typing..."
        />
      </Centered>
    </Sheet>
  );
};

export const PageLinksPane = ({ page }: { page: Page }) => {
  const pageId = usePageId();
  const goTo = useGoTo();
  const related = useLazyEntities(page.refs.related || []);
  const mutate = useQueueUpdates(pageId);
  const relatedDef = useLazyPropertyDef(page.source, {
    field: "refs.related",
    type: "relations",
  });
  const { visible, showMore, hasMore, moreCount } = useShowMore(
    related || [],
    5
  );

  // TODO: Extract out related like from meetings to other entities
  // Move into generic effects
  // Automatically link work that is mentioned in the agendas....
  // useAutoLinkRelated(page, pageId);

  return (
    <Sheet size="secondary">
      <PaneHeader title="Related work" />

      <ResourceExtractor entityId={page.id} />

      <PaneContainer>
        <Menu>
          <MenuGroup>
            {map(visible, (item) =>
              render(toEngine(item)?.asMenuItem, {
                key: item.id,
                item: item,
                onChange: (cs) => mutate(asUpdate(item, cs)),
                onOpen: goTo,
                iconRight: ArrowUpRight,
              })
            )}

            {hasMore && (
              <ShowMoreMenuItem count={moreCount} onClick={showMore} />
            )}

            <GlobalMultiEntitySelect
              value={page.refs.related}
              scope={page.source.scope}
              allowed={when(relatedDef?.options?.references, ensureMany)}
              onChange={(r) =>
                mutate(
                  asUpdate(
                    page,
                    asMutation({ field: "refs.related", type: "relations" }, r)
                  )
                )
              }
              closeOnSelect={true}
              placeholder="Link work"
            >
              <AddMenuItem
                icon={RelationIcon}
                title="Link related work"
                subtle
              />
            </GlobalMultiEntitySelect>
          </MenuGroup>
        </Menu>
      </PaneContainer>
    </Sheet>
  );
};

export const RelatedPagesPane = ({ entityId }: { entityId: ID }) => {
  const pageId = usePageId();
  const pushTo = usePushTo();
  const entity = useLazyEntity(entityId);
  const pages = useLazyEntities(safeAs<HasRefs>(entity)?.refs?.pages || []);
  const mutate = useQueueUpdates(pageId);

  if (!pages?.length) {
    return <></>;
  }

  return (
    <Sheet size="secondary">
      <PaneHeader title="Pages" />

      <PaneContainer>
        <Menu>
          <MenuGroup>
            {map(pages, (item) =>
              render(toEngine(item)?.asMenuItem, {
                key: item.id,
                item: item,
                onChange: (cs) => mutate(asUpdate(item, cs)),
                onOpen: pushTo,
                iconRight: ArrowUpRight,
              })
            )}

            <GlobalEntitySelect
              value={undefined}
              scope={entity?.source.scope}
              allowed={["page"]}
              onChange={(r) =>
                entity &&
                !!r &&
                mutate(
                  asUpdate(
                    entity,
                    asAppendMutation(
                      { field: "refs.pages", type: "relations" },
                      [r]
                    )
                  )
                )
              }
              closeOnSelect={true}
              placeholder="Link page"
            >
              <AddMenuItem
                icon={RelationIcon}
                title="Link existing page"
                subtle
              />
            </GlobalEntitySelect>
          </MenuGroup>
        </Menu>
      </PaneContainer>
    </Sheet>
  );
};
