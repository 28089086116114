import { Pipeline } from "@api";

import { ViewEngine } from "../types";
import { EntityEngine } from "../fallback";

import { PipelineCreateDialog } from "./create-dialog";

export { PipelineCreateDialog } from "./create-dialog";
export { PipelinePage } from "./page";

export const PipelineEngine: ViewEngine<Pipeline> = {
  asMenuItem: EntityEngine.asMenuItem as ViewEngine<Pipeline>["asMenuItem"],
  asListCard: EntityEngine.asListCard as ViewEngine<Pipeline>["asListCard"],
  asListItem: EntityEngine.asListItem as ViewEngine<Pipeline>["asListItem"],
  asCreateDialog: PipelineCreateDialog,
  toViewDefaults: (_id, item) => ({
    group: [item.stageBy || { field: "status", type: "status" }],
    aggregate: [{ field: "value", type: "number", method: "sum" }],
  }),
};
