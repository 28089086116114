import { FC, useMemo } from "react";
import { Entity, EntityType } from "@api";
import { EntityForType } from "@api/mappings";

import { switchEnum } from "@utils/logic";
import { maybeTypeFromId } from "@utils/id";
import { Maybe } from "@utils/maybe";
import { debug } from "@utils/debug";

import { ViewEngine } from "./types";
import { TaskEngine } from "./task";
import { OutcomeEngine } from "./outcome";
import { BacklogEngine } from "./backlog";
import { SprintEngine } from "./sprint";
import { ProjectEngine } from "./project";
import { PersonEngine } from "./person";
import { TeamEngine } from "./team";
import { CampaignEngine } from "./campaign";
import { CalendarEngine } from "./calendar";
import { ScheduleEngine } from "./schedule";
import { ContentEngine } from "./content";
import { NoteEngine } from "./note";
import { ResourceEngine } from "./resource";
import { RoadmapEngine } from "./roadmap";
import { EntityEngine } from "./fallback";
import { ViewsEngine } from "./view";
import { MeetingEngine } from "./meeting";
import { EventEngine } from "./event";
import { PageEngine } from "./page";
import { ProcessEngine } from "./process";
import { FormEngine } from "./form";
import { ActionEngine } from "./action";
import { PipelineEngine } from "./pipeline";
import { DealEngine } from "./deal";
import { ContactEngine } from "./contact";
import { CompanyEngine } from "./company";

export * from "./types";
export * from "./task";

// Update on new entity
export const getEngine = <T extends EntityType, E extends EntityForType<T>>(
  type: T
) =>
  // @ts-expect-error - can't get mappings to work
  switchEnum<EntityType, ViewEngine<E>>(type, {
    task: () => TaskEngine,
    outcome: () => OutcomeEngine,
    project: () => ProjectEngine,
    person: () => PersonEngine,
    sprint: () => SprintEngine,
    backlog: () => BacklogEngine,
    roadmap: () => RoadmapEngine,
    campaign: () => CampaignEngine,
    calendar: () => CalendarEngine,
    content: () => ContentEngine,
    team: () => TeamEngine,
    note: () => NoteEngine,
    resource: () => ResourceEngine,
    meeting: () => MeetingEngine,
    page: () => PageEngine,
    action: () => ActionEngine,
    view: () => ViewsEngine,
    schedule: () => ScheduleEngine,
    process: () => ProcessEngine,
    form: () => FormEngine,
    event: () => EventEngine,
    pipeline: () => PipelineEngine,
    deal: () => DealEngine,
    contact: () => ContactEngine,
    company: () => CompanyEngine,
    // TODO: Add these engine, uses fallback for now
    // knowledgebase: () => KnowledgeBaseEngine,
    // agenda: () => AgendaEngine,
    else: () => {
      debug(`Engine not setup for entity type (${type}).`);
      return EntityEngine;
    },
  });

export const toEngine = <T extends Entity>(entity: T): Maybe<ViewEngine<T>> =>
  getEngine(entity.source.type || maybeTypeFromId(entity.id));

export function useEngine<T extends EntityType>(
  type: T
): ViewEngine<EntityForType<T>>;
export function useEngine<T extends EntityType>(
  type: Maybe<T>
): Maybe<ViewEngine<EntityForType<T>>>;
export function useEngine<T extends EntityType>(
  type: Maybe<T>
): Maybe<ViewEngine<EntityForType<T>>> {
  return useMemo(() => type && getEngine(type), [type]);
}

export const render = <T extends Object>(Comp: Maybe<FC<T>>, props: T) =>
  Comp ? <Comp {...props} /> : <></>;
