import { filter, find, first, flatMap, pick } from "lodash";
import { useState, useCallback, useMemo, useEffect } from "react";

import { Pipeline, EntityType, Ref } from "@api";

import { useCreateView } from "@state/views";
import { useLazyProperties } from "@state/databases";
import { useActiveWorkspaceId } from "@state/workspace";
import {
  useCreateFromObject,
  useEntitySource,
  useQueueUpdates,
} from "@state/generic";

import { Fn } from "@utils/fn";
import { extractTeam } from "@utils/scope";
import { newID } from "@utils/id";
import { getSetting, isAnyRelation, setSetting } from "@utils/property-refs";
import { ensureMany } from "@utils/array";

import { Button } from "@ui/button";
import { Field, TextInput } from "@ui/input";
import { Text } from "@ui/text";
import { FillSpace, SpaceBetween } from "@ui/flex";
import { DialogSplit } from "@ui/dialog-split";
import { Container } from "@ui/container";
import { EducationTip } from "@ui/education-tip";
import PropertySelect, { ScopedPropertySelect } from "@ui/select/property";
import { showError } from "@ui/notifications";
import { MagicEmojiSelect } from "@ui/select/emoji";
import { EntityTypeSelect } from "@ui/select/entity-type";

interface Props {
  defaults: Partial<Omit<Pipeline, "id">>;
  onSaved?: Fn<Ref, void>;
  onCancel?: Fn<void, void>;
}

export const PipelineCreateDialog = ({
  onCancel,
  onSaved,
  defaults,
}: Props) => {
  const wId = useActiveWorkspaceId();
  const scope = useMemo(
    () => defaults?.source?.scope || defaults?.location || wId,
    [defaults?.source?.scope]
  );
  const create = useCreateFromObject("pipeline", scope);
  const createView = useCreateView(extractTeam(scope));
  const mutate = useQueueUpdates();
  const [pipeline, setPipeline] = useState<Partial<Pipeline>>(() => ({
    ...defaults,
  }));
  const childSource = useEntitySource(
    getSetting<EntityType>(pipeline.settings, "child_type"),
    pipeline.source
  );
  const _props = useLazyProperties<EntityType>(childSource);
  const props = useMemo(
    () =>
      filter(_props, (p) =>
        ["select", "multi_select", "number"]?.includes(p.type)
      ),
    [_props]
  );
  const relations = useLazyProperties(pipeline?.source);
  const roadmappable = useMemo(
    () =>
      flatMap(relations, (p) =>
        isAnyRelation(p) &&
        p.options?.references !== "view" &&
        p.options?.hierarchy === "child"
          ? ensureMany(p?.options?.references)
          : []
      ),
    [relations]
  );

  const onCreate = useCallback(() => {
    if (!create) {
      return showError("Not ready.");
    }

    const [saved] = create([pipeline]);
    const transaction = newID();

    if (saved) {
      onSaved?.(saved);
    } else {
      onCancel?.();
    }
  }, [create, pipeline, onSaved]);

  useEffect(() => {
    const defaultt = first(roadmappable);
    if (!defaultt) {
      return;
    }

    setPipeline((r) => ({
      ...r,
      settings: setSetting(r.settings, "child_type", defaultt),
    }));
  }, [roadmappable]);

  return (
    <DialogSplit
      title={"New pipeline"}
      onDismiss={onCancel}
      side={
        <SpaceBetween direction="vertical">
          <Text subtle>
            Pipelines are about organising any kind of work, whether be big or
            small.
          </Text>
          <EducationTip relevantTo={["pipeline"]} />
        </SpaceBetween>
      }
      actions={
        <>
          <Button onClick={() => onCancel?.()}>Cancel</Button>
          <Button variant="primary" onClick={onCreate}>
            Create pipeline
          </Button>
        </>
      }
    >
      <FillSpace direction="vertical">
        <Container gap={20} stack="vertical" fit="container">
          <>
            <Field layout="horizontal">
              <MagicEmojiSelect
                key={pipeline.id || "new-pipeline"}
                entity={pipeline as Pipeline}
                size="xlarge"
                emoji={pipeline.icon}
                onChange={(icon) => setPipeline((r) => ({ ...r, icon }))}
              />
            </Field>

            <Field label="Name">
              <TextInput
                value={pipeline.name || ""}
                onChange={(t) => setPipeline((b) => ({ ...b, name: t }))}
                updateOn="change"
                autoFocus={true}
                placeholder="Enter pipeline name..."
              />
            </Field>

            <Field label="Organising">
              <EntityTypeSelect
                value={childSource?.type}
                allowed={roadmappable}
                portal
                scope={scope}
                onChange={(entity) =>
                  setPipeline((r) => ({
                    ...r,
                    settings: setSetting(r.settings, "child_type", entity),
                  }))
                }
              />
            </Field>

            {!!childSource?.type && (
              <SpaceBetween gap={10}>
                <Field label="Pipeline Stages">
                  <ScopedPropertySelect
                    placeholder="Choose fields..."
                    whitelist={(p) =>
                      p.type === "select" || p.type === "status"
                    }
                    scope={childSource.scope}
                    type={childSource.type}
                    portal={true}
                    value={pipeline.stageBy}
                    onChanged={(p) =>
                      p &&
                      setPipeline((b) => ({
                        ...b,
                        stageBy: pick(p, ["field", "type"]),
                      }))
                    }
                  />
                </Field>
              </SpaceBetween>
            )}
          </>
        </Container>
      </FillSpace>
    </DialogSplit>
  );
};
